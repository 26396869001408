import { AbstractControl, ValidationErrors } from '@angular/forms';

export const regexValidator = (regex: RegExp, error: ValidationErrors) => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    const valid = regex.test(control.value);
    return valid ? null : error;
  };
};
