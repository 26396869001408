/* eslint-disable prefer-regex-literals */
/* eslint-disable @typescript-eslint/prefer-for-of */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * This is a utility class to validate the passwords, based on the required validations.
 *
 * NB: Caution when changing this utility class, because it will be shared in many parts
 * of the application.
 */

const BOTH_UPPER_AND_LOWER_REGEX_MATCH = new RegExp('^(?=.*[a-z])(?=.*[A-Z])', 'g');
const NUM_AND_SPECIAL_CHAR_REGEX_MATCH = new RegExp(
  // eslint-disable-next-line no-useless-escape
  `^(?=.*["!#\$%&'()*+,-./:;<=>?@[\\]^_\`{|}~])(?=.*[0-9])`,
  'g',
);

export class PasswordValidatorUtility {
  public static containUpperAndLower(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      BOTH_UPPER_AND_LOWER_REGEX_MATCH.test(control.value) ? null : error;
  }

  public static containNumAndSpecialChar(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      NUM_AND_SPECIAL_CHAR_REGEX_MATCH.test(control.value) ? null : error;
  }

  /**
   * Other validation checks can be added here
   */
}
